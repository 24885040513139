import React, { useState, useCallback, useEffect } from 'react';
import { Link } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Popup from '../components/Popup';
import '../styles/policy.scss';

const PrivacyPolicy = () => {
  const [modalVisible, setModalVisible] = useState('');

  const togglePopup = useCallback(
    (value) => setModalVisible((state) => (state === value ? '' : value)),
    [],
  );
  const closePopup = useCallback(() => setModalVisible(0), []);

  useEffect(() => {
    modalVisible
      ? document.body.classList.add('overflow')
      : document.body.classList.remove('overflow');
  }, [modalVisible]);

  return (
    <Layout togglePopup={togglePopup}>
      <SEO title="Privacy Policy" />
      <Popup modalVisible={modalVisible === 'spaces'} closePopup={closePopup}>
        <div className="space-video">
          <video
            autoPlay
            loop
            muted
            playsInline
            src="/video/spaces.mp4"
            style={{ width: '500px' }}></video>
        </div>
        <h4>Spaces by Brutask</h4>
        <p>
          Spaces by Brutask is created to replicate your virtual office.
          Navigate across floors, join a room &amp; discuss with your team via
          audio &amp; video. You can also share your screen with all othe
          participants inside the room. What's better - you can share the room
          link with external members outside your team, and they can join
          without any login!
        </p>
        <a className="button" href="http://app.brutask.com/">
          Get started
          <svg width="13" height="13" viewBox="0 0 13 13" fill="none">
            <path
              d="M6.60019 0.199951L5.4722 1.32795L9.93619 5.79995H0.200195V7.39995H9.93619L5.4722 11.872L6.60019 13L13.0002 6.59995L6.60019 0.199951Z"
              fill="white"
            />
          </svg>
        </a>
        <a
          className="button button-unstyled"
          href="https://www.loom.com/share/c0ad1d9ddb6f48fe9e1040c7f6550ca3"
          target="_blank"
          rel="noopener noreferrer">
          Watch Demo
        </a>
      </Popup>
      <Popup
        modalVisible={modalVisible === 'pricing'}
        closePopup={closePopup}
        className="popup-center">
        <h3>
          Pay as you <span className="yellow">grow</span>
        </h3>
        <p>
          You will be charges <span className="semi-bold">$15/month</span> for
          the plan that will include: 10 members, 3 floors and unlimited guest
          access. <span className="semi-bold">$2</span> would be charged for
          every additional member
        </p>
        <a className="button" href="http://app.brutask.com/">
          Get started
          <svg width="13" height="13" viewBox="0 0 13 13" fill="none">
            <path
              d="M6.60019 0.199951L5.4722 1.32795L9.93619 5.79995H0.200195V7.39995H9.93619L5.4722 11.872L6.60019 13L13.0002 6.59995L6.60019 0.199951Z"
              fill="white"
            />
          </svg>
        </a>
      </Popup>
      <section className="policy">
        <div className="container">
          <div className="inner-common-hero">
            <h1 className="privacy-head">Privacy Policy</h1>
            <p>Effective Date: 20 April 2022</p>
          </div>
        </div>
        <section>
          <div className="container">
            <div className="privacy-content">
              <h2>1. INTRODUCTION</h2>
              <p>
                This Privacy Policy sets forth the privacy practices of Brutask
                and its affiliates and subsidiaries (collectively, Brutask) with
                respect to all information, data, graphics, content, and
                software applications (including, without limitation, mobile
                software and web applications) (collectively, the “Software”);
                the Brutask website, located at https://brutask.com, (the
                “Website”); and other products or services provided or otherwise
                made accessible on or through the Software or the Website.
                Collectively, the Software, the Website, and any other Brutask
                services are hereinafter referred to as the “Service”. In this
                Privacy Policy, “we”, “us”, “our” and other similar references
                mean Brutask and “you” and “your” and other similar references
                mean any particular user of the Service. This Privacy Policy
                describes how Brutask uses information of users collected
                through our Service.
              </p>
              <p>
                This policy is applicable (i) immediately to users who utilize
                the Service on or after the Effective Date and (ii) on the
                Effective Date for users who utilize the Service before the
                Effective Date. Any person who does not agree to be bound by
                this Privacy Policy should not utilize the Service. Each
                capitalized term not otherwise defined herein shall have the
                meaning set forth in Section 2.
              </p>
              <p>
                Please contact us if you have any questions or comments about
                our privacy practices or this Privacy Policy. You can reach us
                online at{' '}
                <a
                  href="mailto:support@brutask.com"
                  className="green semi-bold">
                  support@brutask.com
                </a>
                .
              </p>
              <p>
                Please read this privacy policy carefully. By accessing or using
                the service, you acknowledge that you have read, understood, and
                agree to be bound to all the terms and conditions of this
                privacy policy and our{' '}
                <Link to="/terms-and-conditions" className="green semi-bold">
                  terms of use.
                </Link>
                . If you do not agree to these terms and conditions, please
                exit, and do not access or use the service.
              </p>

              <h2>2. DEFINITIONS</h2>
              <ol>
                <li>“Contact Information” means your email address.</li>
                <li>
                  “Effective Date” means the date set forth at the beginning of
                  this document.
                </li>
                <li>
                  “Brutask Affiliates” means any parent, subsidiary, member,
                  officer, director, employee, or agent of Brutask or any
                  company under common control with Brucira.
                </li>
                <li>
                  “Privacy Policy” means the set of policies set forth in this
                  document.
                </li>
                <li>
                  “Transaction Information” means all information related to
                  transactions that you conduct on the Service and your
                  interactions with the Service.
                </li>
                <li>
                  “User Account” means an account permitting access to the
                  Service, which is associated with a sign-in using Google's
                  O-Auth.
                </li>
                <li>
                  “User Account Information” means information that identifies
                  you to the Service, including your user name, password,
                  internet protocol address, and MAC address.
                </li>
                <li>
                  “User Content” means images, comments, and other materials
                  that you post to the Service.
                </li>
              </ol>

              <h2>3. TYPES OF INFORMATION WE COLLECT</h2>
              <p>
                Brutask collects the following types of Information through the
                Service:
              </p>
              <div className="no-margin-ol-wrapper">
                <ol>
                  <li>User Account Information</li>
                  <li>User Profile Information</li>
                  <li>User Content</li>
                  <li>Log Data</li>
                  <li>Cookies</li>
                  <li>Payment Information</li>
                </ol>
              </div>
              <ol>
                <li>
                  <span className="bold">User Account Information:</span>
                  <p>
                    If you sign-up using your email address and password, we do
                    store your entered name, email address and password in an
                    encrypted format. If you sign-in using your Google account,
                    we store your Name (as added to your Google Account) and
                    Gmail address. Please do keep in mind that the above
                    information in both scenarios (email sign-up or Google
                    sign-in) is only stored for the purpose of identifying your
                    account when you login to our platform henceforth, and none
                    of our team members or employees share, copy, collate or
                    write this information down in any manner.
                  </p>
                </li>
                <li>
                  <span className="bold">User Profile Information</span>
                  <p>
                    Your user profile information can be seen once you click on
                    the Profile tab in{' '}
                    <a
                      href="https://app.brutask.com/settings"
                      className="green semi-bold">
                      this link.
                    </a>{' '}
                    If you sign-up by entering your name, email address and
                    password, we do store your entered name and email address;
                    password being a security checker to confirm your identity
                    before we grant you access to this account. If you sign-in
                    using your Google account, we store your Name (as added to
                    your Google Account) and Gmail address.
                  </p>
                  <p>
                    There is other information in our Profile section as well,
                    which you may choose to fill in, such as:
                  </p>
                  <div className="numbered-list">
                    <ol>
                      <li>
                        Profile details - Mobile Number, Date of Birth, Gender,
                        Postal Address, Emergency Mobile Number
                      </li>
                      <li>
                        Job Details - Designation along with your Employee ID
                        Number
                      </li>
                      <li>
                        Bank account details - Account Holder Name, Bank Name,
                        IFSC Code along with your Account Number
                      </li>
                    </ol>
                  </div>
                  <p>
                    This information is stored only for your team's admin(s) as
                    a one-point reference source material for the entire team.
                    Brutask does not share, copy, collate or write this
                    information down in any manner. Also, this information is
                    shared only within your team's admin(s), until one OR a few
                    OR all of those team admin(s) choose to intentionally share
                    this outside the team. This information is shared between
                    you, and your Brutask team's admin(s) via an SSL-secured
                    environment. When your account is deleted, all the
                    information related to it is also deleted instantly. Brutask
                    does not store any account information after the deletion of
                    the account.
                  </p>
                </li>
                <li>
                  <span className="bold">User Content</span>
                  <p>User content contains the following elements:</p>
                  <div className="numbered-list">
                    <ol>
                      <li>Tasks added by you in your today's tasklist</li>
                      <li>
                        Tasks added by the Brutask team's admin(s) or your
                        Brutask team's manager(s) in your tasklist
                      </li>
                      <li>Tasks added by you in others tasklist(s)</li>
                      <li>Future-dated tasks added by you in your sidebar</li>
                      <li>
                        Future-dated tasks added by you in your sidebar and
                        assigned to self
                      </li>
                      <li>
                        Future-dated tasks added by you in your sidebar and
                        assigned to a few OR all other team members
                      </li>
                      <li>
                        Calendar events - In case you integrate your Google
                        Calendar with Brutask, the email addresses of all
                        participants in your calendar events will be
                        automatically captured. This capturing is only displayed
                        in a modal for you to respond to the Google
                        meeting/event
                      </li>
                      <li>
                        Audio & Video - Joining a call by using Spaces by
                        Brutask will require capturing audio or video (or both)
                        of all participants included in the call. This audio
                        and/or video will be captured only for the purpose of
                        being transmitted over a secure connection to all other
                        participants so that a successful call can be carried
                        out between all participants included in the call. Your
                        calls are end-to-end browser-secured, and Brutask
                        doesn't have the authority to spy on your audio and/or
                        video. All conversations are private between the
                        participants of the call unless externally recorded by
                        participant(s) and shared externally. Brutask only
                        provides a medium/mode for two or more participants to
                        connect over call, and any information transmitted over
                        the call is not stored by us in any format.
                      </li>
                      <li>
                        Screen data - If you choose to share your screen while
                        being part of the Spaces call, your screen data will be
                        captured. The extent of screen capture (tab, window or
                        entire screen) will completely depend upon the selection
                        made by you while sharing the screen, and this data will
                        be transmitted over a secure connection to all/other
                        participants who are active in that call. Brutask
                        doesn't have the authority to spy on your audio, video
                        or screen sharing. All screen recording sessions are
                        private between the participants of the call unless
                        externally recorded by participant(s) and shared
                        externally. Brutask only provides a medium/mode for two
                        or more participants to share their preferred screen
                        over a call, and any information transmitted over the
                        call is not stored by us in any format.
                      </li>
                    </ol>
                  </div>
                </li>
                <li>
                  <span className="bold">Log Data</span>
                  <p>
                    We want to inform you that whenever you use our Service, in
                    the case of an error in the app we collect data and
                    information (through third-party products) on your phone
                    called Log Data. This Log Data may include information such
                    as your device Internet Protocol (“IP”) address, device
                    name, operating system version, the configuration of the app
                    when utilizing our Service, the time and date of your use of
                    the Service, and other statistics.
                  </p>
                </li>
                <li>
                  <span className="bold">Cookies</span>
                  <p>
                    Cookies are files with a small amount of data that are
                    commonly used as anonymous unique identifiers. These are
                    sent to your browser from the websites that you visit and
                    are stored on your device's internal memory.
                  </p>
                  <p>
                    This Service does not use these “cookies” explicitly.
                    However, the app may use third-party code and libraries that
                    use “cookies” to collect information and improve their
                    services. You have the option to either accept or refuse
                    these cookies and know when a cookie is being sent to your
                    device. If you choose to refuse our cookies, you may not be
                    able to use some portions of this service, thereby not being
                    able to fully use our service as intended.
                  </p>
                </li>
                <li>
                  <span className="bold">Payment Information</span>
                  <p>
                    We have integrated with Stripe for the purpose of collecting
                    payments from our users. Stripe offers you the opportunity
                    to store your payment methods with Stripe so that you can
                    conveniently use them across our platform.{' '}
                    <a
                      href="https://stripe.com/en-in/privacy"
                      className="green semi-bold">
                      Learn more
                    </a>
                  </p>
                </li>
              </ol>

              <h2>4. MECHANISMS FOR COLLECTION OF INFORMATION</h2>
              <p>
                Brutask collects Information (i) upon a user's creation of a
                User Account, (ii) upon logging into the Service, (iii) upon
                interactions with the Service, and (iv) upon uploading User
                Content to, or generating User Content on the Service, (v) when
                you communicate with Brutask through the Service. When you
                access our Service, we may place small data files on your
                computer or other device known as “cookies”. We use cookies to
                recognize you as a user of the Service. Cookies may remain on
                your device until you erase them or they expire. You are free to
                decline our cookies if your browser or browser add-on permits,
                but doing so may interfere with your use of the Service. You
                should refer to the help sections of your browser, browser
                extensions, or installed applications for instructions on
                blocking, deleting, or disabling cookies.
              </p>

              <h2>5. PURPOSE OF COLLECTION AND USE OF INFORMATION</h2>
              <p>Brutask uses the information to:</p>
              <ol>
                <li>operate and improve the Service;</li>
                <li>
                  administer the Service's facilities for communications among
                  and between users;
                </li>
                <li>provide user support;</li>
                <li>
                  inform users about new and changed functionality in Brutask
                  Services;
                </li>
              </ol>
              <p>
                The app does use third-party services that may collect
                information used to identify you. The link to the privacy policy
                of third-party service providers used by the app is as follows:
              </p>
              <ul>
                <li>
                  Google Firebase -{' '}
                  <a
                    href="https://firebase.google.com/support/privacy"
                    className="green semi-bold word-break">
                    https://firebase.google.com/support/privacy
                  </a>
                </li>
                <li>
                  Agora.io -{' '}
                  <a
                    href="https://www.agora.io/en/privacy-policy/"
                    className="green semi-bold word-break">
                    https://www.agora.io/en/privacy-policy/
                  </a>
                </li>
                <li>
                  Google Analytics for Firebase -{' '}
                  <a
                    href="https://firebase.google.com/policies/analytics"
                    className="green semi-bold word-break">
                    https://firebase.google.com/policies/analytics
                  </a>
                </li>
                <li>
                  Stripe -{' '}
                  <a
                    href="https://stripe.com/en-in/privacy"
                    className="green semi-bold word-break">
                    https://stripe.com/en-in/privacy
                  </a>
                </li>
              </ul>
              <p>
                Brutask's use of information received, and Brutask's transfer of
                information to any other app, from Google APIs, will adhere to
                Google's Limited Use Requirements.
                <h2>6. DISCLOSURE OF INFORMATION</h2>
                <p>We may disclose your information to:</p>
                <ol>
                  <li>
                    service providers who act for us or provide services for us,
                    such as for customer support or for the processing of
                    payments, and to such service providers their use of
                    information is subject to our agreements with them and any
                    applicable laws;
                  </li>
                  <li>
                    vendors in connection with the investigation of possible
                    fraud, or as part of legal proceedings affecting Brutask or
                    the vendor;
                  </li>
                  <li>
                    any person or government agency in response to a subpoena;
                  </li>
                  <li>
                    to a government agency in response to an investigation,
                    regardless of whether or not a subpoena has been issued;
                  </li>
                  <li>
                    any person to enforce our rights or protect our property; to
                    protect the rights, property or safety of others,
                    investigate fraud; and Brutask employees and outside
                    professionals (such as lawyers and accountants) for the
                    purpose of auditing, compliance, and corporate governance.
                  </li>
                </ol>
              </p>

              <h2>7. STORAGE AND SECURITY</h2>
              <p>
                We believe the security of your information is a serious issue,
                and we are committed to protecting the information we receive
                from you. We use commercially reasonable security measures to
                protect against the loss, misuse and alteration of your
                information under our control. Notwithstanding such measures,
                please be aware that no security measures are perfect or
                impenetrable, and we cannot guarantee the security of any
                information transmitted to or from the Service. We also are not
                responsible for the actions of any third parties that may
                receive any such information.
              </p>
              <p>
                We may transfer your information to, or maintain it on,
                computers located outside of your state or country, in a
                location having laws that are less protective of your privacy.
                If you are located outside India and choose to provide
                information to us, be advised that Brutask is located in India
                and processes your information in India. By using the Service
                under this Privacy Policy and the Terms of Service, you consent
                to the transfer of your information to India.
              </p>
              <p>
                We will retain information only as long as our business needs
                require. We will then destroy or render unreadable any such
                information upon disposal.
              </p>

              <h2>8. EMAIL POLICIES</h2>
              <p>
                Brutask reserves the right to send you emails relating to your
                account and to the Service. You can opt out of receiving such
                emails if you wish to, but until then, you agree to receive
                them.
              </p>

              <h2>
                9. NO RESPONSIBILITY FOR PRIVACY PRACTICES OF THIRD PARTIES
              </h2>
              <p>
                This Privacy Policy applies only to the Service. It does not
                apply to sites that have linked or are redirected to or from the
                Service (“Third Party Sites”), regardless of whether or not such
                link or redirection is authorized by Brutask. Also, we use
                third-party services which have their own privacy policies.
                Third-Party Sites may have their own policies regarding privacy
                or no policy at all. Brutask is not responsible for Third Party
                Sites and you use them at your own risk.
              </p>

              <h2>10. INTERACTIONS WITH OTHERS</h2>
              <p>
                The Service may contain areas where you may be able to post
                information and communicate with others. Such use is subject to
                our Terms of Service and may be viewed by the public at large.
                Any information, including information that you post there may
                be public, and therefore anyone who accesses such postings will
                have the ability to read, collect and further disseminate such
                information.
              </p>

              <h2>11. ACCESSING AND CORRECTING INFORMATION</h2>
              <p>
                Your information (only name and email address) is collected
                directly from your Google account. We do not, and will not have
                access to any other private or sensitive information from your
                Google account, like, your password.
              </p>
              <p>
                We use analytics to collect diagnostic and usage data to improve
                our services and troubleshoot errors. Users cannot opt out of
                this.
              </p>

              <h2>12. CHANGES TO THE PRIVACY POLICY</h2>
              <p>
                Brutask reserves the right to change this Privacy Policy. Such
                changes may be announced to you by the posting of the revised
                policy on the Service. You are responsible for reviewing the
                Service to determine what is the current Privacy Policy. We may
                update our Privacy Policy from time to time. Thus, you are
                advised to review this page periodically for any changes. We
                will notify you of any changes by posting the new Privacy Policy
                on this page. This policy is effective as of 2020-12-19.
              </p>

              <h2>13. NOTICE TO RESIDENTS OF COUNTRIES OUTSIDE INDIA</h2>
              <p>
                Brutask is headquartered in Mumbai, India. Information may be
                accessed by us or transferred to us in India or to Brutask
                Affiliates, business partners, vendors, or service providers
                elsewhere in the world. By providing us with your information,
                you consent to this transfer. Brutask will adhere to its Privacy
                Policy, regardless of where the information is processed or
                stored.
              </p>
            </div>
          </div>
        </section>
      </section>
    </Layout>
  );
};

export default PrivacyPolicy;
